*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #000;
  color: #fff;
  font-size: 28px;
}

.card {
  background-color: #fff;
  color: #000;
  border-radius: 8px;
  width: 600px;
  margin: 20px auto;
  padding: 30px;
  text-align: center;
  box-shadow: 2px 2px 5px rgba(255, 255, 255, .7);
}


.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0, .7);
}

.modal_card {
  width: 700px;
  background-color: #000;
  margin: 200px auto;
  padding: 100px;
  color: #fff;
  border-radius: 8px;
  box-shadow: 2px 2px 17px 5px rgb(255, 255, 255);
}

label {
}

span {
  display: inline-block;
  width: 100%;
  text-transform: uppercase;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 30px;
  font-size: 22px;
}

.btn_container {
  width: 20%;
  margin: 0 auto;
}

button {
  width: 100%;
  border: none;
  border-radius: 8px;
  background-color: #ddd;
  color: #000;
  padding: 20px;
  margin: 8px;
  font-size: 22px;
  text-transform: uppercase;
}